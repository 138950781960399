import * as React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "../../utils/auth";

// TODO not being used until resolving auth method
const PrivateRoute = ({ component: Component, location, ...rest }: any) => {
  const [isAuthorized, setIsAuthorized] = React.useState(null);
  React.useEffect(() => {
    const fetchAuthStatus = async () => {
      const response = await isLoggedIn();
      setIsAuthorized(response);
    };

    fetchAuthStatus();
  }, [isLoggedIn, setIsAuthorized]);

  if (isAuthorized == null) {
    return <p>Authorizing...</p>;
  } else if (isAuthorized == false) {
    navigate("/resources/login");
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
