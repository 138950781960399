import * as React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import Layout from "../components/layout";
import Home from "../components/Login/Home";
import Login from "../components/Login/Login";
import PrivateRoute from "../components/Login/PrivateRoute";
import SEO from "../components/seo";

const Resources = () => {
  // TODO Removed login page for now as it wasn't doing much anyways, but uncomment these for routing.

  // let Home = (props: RouteComponentProps) => <Home />;
  // let Login = (props: RouteComponentProps) => <Login />;

  return (
    <Layout title={"Resources"}>
      <SEO title="Resources" />
      {/* <Home /> */}
      {/* TODO If want to have a private routes this will need to be uncommented */}
      <Router basepath="/resources">
        <Login default={true} path="/login" />
        <PrivateRoute path="/home" component={Home} />
      </Router>
    </Layout>
  );
};

export default Resources;
