// TODO Turns out pretty much all of this page was copied and pasted from an auth example on Gatsby
// TODO where the tutorial explicitly states this is an insecure example of auth "flow" not a prod-ready solution.

import { navigate } from "gatsby";
import { AuthResponse, UserCredentials, UserNode } from "../../netlify/functions/authorize";

const isBrowser = typeof window !== `undefined`;

const getUser = () => {
  return JSON.parse(window.localStorage.getItem("gatsbyUser")) || {};
};

const setUser = (user: UserNode, remember: boolean) => {
  // Save the user credentials in the session storage if remember
  // is false or in the local storage if remember is true.
  if (remember) {
    window.localStorage.setItem(
      "gatsbyUser",
      JSON.stringify(user.resourcePasswords)
    );
  } else {
    window.sessionStorage.setItem(
      "gatsbyUser",
      JSON.stringify(user.resourcePasswords)
    );
  }
};

const authorizePost = async (
  credentials: UserCredentials
): Promise<AuthResponse> => {
  return await window
    .fetch(`/.netlify/functions/authorize`, {
      method: `POST`,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
    .then((res) => res.json());
};

export const handleLogin = async (
  username: string,
  password: string,
  remember: boolean
) => {
  if (!isBrowser) return;
  const credentials: UserCredentials = { username, password };
  const response: AuthResponse = await authorizePost(credentials);

  if (response.isAuthorized) {
    setUser(response.user, remember);
    navigate("/resources/home");
  }

  return response;
};

export const isLoggedIn = async () => {
  if (!isBrowser) return false;

  if (
    window.sessionStorage.getItem("gatsbyUser") ||
    window.localStorage.getItem("gatsbyUser")
  ) {
    let credentials =
      JSON.parse(window.sessionStorage.getItem("gatsbyUser")) ||
      JSON.parse(window.localStorage.getItem("gatsbyUser"));

    const response: AuthResponse = await authorizePost(credentials);

    return response?.isAuthorized;
  } else {
    return false;
  }
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback: Function) => {
  if (!isBrowser) return;

  setUser(undefined, false);
  setUser(undefined, true);

  callback();
};
