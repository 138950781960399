import * as React from "react";
import { useState, useEffect } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { handleLogin, isLoggedIn } from "../../utils/auth";
import styled from "@emotion/styled";

const InputConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #005285;
  font-weight: bold;
  font-size: 1.5rem;

  input[type="text"] {
    margin-left: 0.5rem;
    border-color: black;
    border-width: thin;
    border-radius: 0.25rem;
    margin-bottom: 0.75rem;
  }

  input[type="password"] {
    margin-left: 0.4rem;
    border-color: black;
    border-width: thin;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
  }

  input[type="checkbox"] {
    margin-right: 0.5rem;
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
  }

  input[type="submit"] {
    color: white;
    background: #005285;
    height: 2.5rem;
    width: 8.5rem;
    border-radius: 7rem;
    font-size: 0.75rem;
  }
`;

const CheckboxContainer = styled.div`
  font-size: 1rem;
  padding-bottom: 2rem;
`;

const ErrorMessage = styled.p`
  // default line-height of the p tag is 1.71
  // calculated so that when text is displayed inside paragraph element displacement does not occur.
  min-height: calc(1rem * 1.71);
  color: red;
  font-weight: bold;
  text-align: center;
`;

const Title = styled.h2`
  text-align: center;
`;

// Removed Login page for now, because the query to get user credentials no longer works, and don't see a possbile replacement for now.
const Login = () => {
  const results = useStaticQuery(graphql`
    query getCredentials {
      allWpLogin {
        nodes {
          resourcePasswords {
            username
            password
          }
        }
      }
    }
  `);

  const credentials = results.allWpLogin.nodes;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      let response = await handleLogin(username, password, remember);
      setErrorMessage(response.message);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    let mounted = true;
    const fetchAuthStatus = async () => {
      const response = await isLoggedIn();
      setIsAuthorized(response);
    };
    if (mounted) {
      fetchAuthStatus();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [isLoggedIn, setIsAuthorized]);

  if (isAuthorized == null) {
    return <p>Loading...</p>;
  } else if (isAuthorized == true) {
    navigate("/resources/home");
    return null;
  }

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <Title>Log In</Title>
      <InputConatiner>
        <label>
          USERNAME:
          <input
            type="text"
            name="username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          PASSWORD:
          <input
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <CheckboxContainer>
          <label>
            <input
              type="checkbox"
              name="remember"
              onChange={(e) => setRemember(e.target.checked)}
            ></input>
            REMEMBER ME
          </label>
          <br />
        </CheckboxContainer>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <input type="submit" value="LOG IN" />
      </InputConatiner>
    </form>
  );
};

export default Login;
