import * as React from "react";
import { useStaticQuery, graphql, GatsbyLinkProps, Link } from "gatsby";
import ResourceList from "../Helpers/ResourceList";
import Layout from "../layout";
import SEO from "../seo";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../utils/css/themes";
import ExpandOnHover from "../../components/Helpers/ExpandOnHover";
import { GatsbyImage } from "gatsby-plugin-image";

const Home = () => {
  const StyledLink = styled((props: any) => <Link {...props} />)`
    text-decoration: none;
  `;

  const TilesContainer = styled.div`
    max-width: 970px;
    margin: 0 auto 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  `;

  const ImgCss = css`
    width: 200px;
    height: auto;
    margin: auto;
  `;

  const SponsorName = styled.div`
    font-family: Baloo;
    font-size: 24px;
    line-height: 1.08;
    letter-spacing: -1.13px;
    text-align: center;
    color: ${colors.blue};
    padding-top: 1rem;
    height: 100%;
    max-width: 240px;
    margin: 0 auto;
  `;

  const SponsorWebsite = styled.div`
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: 1rem;
    line-height: 1.43;
    letter-spacing: 0.58px;
    text-align: center;
    color: ${colors.blue};
    text-transform: uppercase;
  `;

  const Arrow = styled.span`
    color: ${colors.orange};
  `;

  const TileAnchor = styled.a`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0.5rem 0;
    width: 302px;
    display: flex;
    flex-direction: column;
    height: 302px;
    border-radius: 4px;
    justify-content: space-around;
    box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    text-decoration: none;
  `;
  const TileDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0.5rem 0;
    width: 302px;
    height: 302px;
    border-radius: 4px;
    justify-content: space-between;
    box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    text-decoration: none;
  `;
  const resourcePost = useStaticQuery(graphql`
    query getEducatorResourceCenterPost {
      wpPost(title: { eq: "Educator Resource Center" }) {
        title
        content
        sponsors {
          sponsors {
            name
            website
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 195, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  `).wpPost;

  let sponsorsAcf = resourcePost.sponsors;

  // TODO Style seems to not be working, I think global styles are not being applied

  function AddTile(data: any, index: number, Tile: any) {
    return (
      <ExpandOnHover key={index}>
        <StyledLink to={data?.website || "#"}>
          <Tile>
            <div
              css={css`
                max-height: 195px;
                max-width: 195px;
                margin: 1rem auto 0;
              `}
            >
              {data.image && data.image.localFile && (
                <GatsbyImage
                  image={data.image.localFile.childImageSharp.gatsbyImageData}
                  css={ImgCss}
                  alt={"highlight image"}
                />
              )}
              {data.image && !data.image.localFile && (
                <img
                  src={data.image.source_url}
                  alt={""}
                  css={[
                    ImgCss,
                    css`
                      object-fit: cover;
                    `,
                  ]}
                ></img>
              )}
            </div>
            <div
              css={css`
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
              `}
            >
              <SponsorName>{data.name}</SponsorName>
              <SponsorWebsite
                css={css`
                  ${data.website === "" ? "display:none" : "display:block"}
                `}
              >
                {" "}
                Resources <Arrow>&rarr;</Arrow>
              </SponsorWebsite>
            </div>
          </Tile>
        </StyledLink>
      </ExpandOnHover>
    );
  }

  function GenereateTiles() {
    if (!sponsorsAcf?.sponsors) {
      return (
        <p>
          Could not find any Sponsors data in "Educator Resource Center" post.
        </p>
      );
    }

    let tiles = sponsorsAcf.sponsors.map((data: any, index: number) => {
      return AddTile(data, index, TileDiv);
    });

    return <TilesContainer>{tiles}</TilesContainer>;
  }

  return <div>{GenereateTiles()}</div>;
};

export default Home;
